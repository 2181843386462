/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import Header from "./header"
import "bootstrap/dist/css/bootstrap.min.css"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allContentfulPages {
        edges {
          node {
            slug
            title
          }
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        data={data.allContentfulPages.edges}
      />

      {children}
      <Container
        fluid
        style={{
          backgroundColor: "rgb(253, 253, 253)",
          borderTop: "1px solid rgb(242, 242, 242)",
        }}
      >
        <Container>
          <Row style={{ paddingTop: 20, marginBottom: 20 }}>
            <Col md={4}>
              <b>Products</b>
              <br />
              {data.allContentfulPages.edges.map(function(i, key) {
                return (
                  <>
                    <Link to={i.node.slug} style={{ color: "#000" }}>
                      <span>{i.node.title}</span>
                    </Link>
                    <br />
                  </>
                )
              })}
            </Col>
            <Col md={4}>
              <b>Social Media</b>
              <br />
              <span>
                <a
                  href="https://www.facebook.com/groups/EXPATHEALTH"
                  style={{ color: "#000" }}
                >
                  Expat Health Insurance Group
                </a>
              </span>
              <br />
              <span>
                <a
                  href="https://www.facebook.com/Thailand-Expat-Long-Stay-Health-Insurance-107398824112432/"
                  style={{ color: "#000" }}
                >
                  Thailand Expat & Long Stay Health Insurance
                </a>
              </span>
              <br />
              <span>
                <a
                  href="https://www.facebook.com/groups/INTERNATIONALTRAVELTIPSandPics/"
                  style={{ color: "#000" }}
                >
                  International Travel Tips & Pics Group
                </a>
              </span>
              <br />
              <span>
                <a
                  href="https://www.facebook.com/www.HEALTHINSURANCE.TRAVEL/"
                  style={{ color: "#000" }}
                >
                  HealthInsurance.Travel
                </a>
              </span>
            </Col>
            <Col md={4}>
              <b>Contact Us</b>
              <br />
              <p>
                Email:{" "}
                <a
                  href="mailto:info@healthplansthai.com"
                  style={{ color: "#000" }}
                >
                  info@healthplansthai.com
                </a>
                <br />
                Thailand Phone Number:{" "}
                <a href="tel:025660250" style={{ color: "#000" }}>
                  025 660 250
                </a>
                <br />
                International Number:{" "}
                <a href="tel:+6625660250" style={{ color: "#000" }}>
                  +66 25 660 250
                </a>
                <br />
                Mobile Number:{" "}
                <a href="tel:0948961727" style={{ color: "#000" }}>
                  094 896 1727
                </a>
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              Copyright © {new Date().getFullYear()} healthplansthai.com
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
